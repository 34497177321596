<template>
  <div class="mt-4">
    <div v-if="isLoading" class="d-flex justify-content-center m-5">
      <div class="spinner-border text-primary">
        <span class="sr-only">{{$t('state.loading')}}</span>
      </div>
    </div>
    <b-form v-if="!isLoading">
      <div class="w-100">
        <b-form-group
            :label="$t('cms.resource.title')"
            label-for="title"
            label-cols-lg="3"
            class="bg-light"
            style="padding: 10px 2px;"
        >
          <b-form-input
              id="title"
              type="text"
              v-model="title"
              @change="$v.title.$model = $event"
              @update="setIsEditResource"
              :state="!$v.title.$dirty ? null :(!$v.title.$error)"
              trim></b-form-input>
          <p v-if="$v.title.$dirty && !$v.title.required" class="text-danger">
            {{ $t('administrator.errors.required') }}
          </p>
        </b-form-group>

        <b-form-group
            :label="$t('cms.resource.menu_title')"
            label-for="menu-title"
            label-cols-lg="3"
        >
          <b-form-input
              id="menu-title"
              type="text"
              v-model="menuTitle"
              @update="setIsEditResource"
              trim></b-form-input>
        </b-form-group>

        <b-form-group
            :label="$t('cms.resource.parent_id')"
            label-for="date-format"
            label-cols-lg="3"
            class="bg-light"
            style="padding: 10px 2px;"
        >
          <b-form-select
              id="parent-id"
              v-model="parentId"
              :options="filteredAvailableParents"
              :disabled="!availableParents.length"
              @update="setIsEditResource"
              value-field="id"
              text-field="title"
          ></b-form-select>
        </b-form-group>

        <b-form-group
            :label="$t('cms.resource.status')"
            label-for="status"
            label-cols-lg="3"
        >
          <b-form-select
              id="status"
              v-model="status"
              :options="statuses"
              @update="setIsEditResource"
              value-field="value"
              text-field="text"
          ></b-form-select>
        </b-form-group>

        <b-form-group
            :label="$t('cms.resource.locale')"
            label-for="locale"
            label-cols-lg="3"
            class="bg-light"
            style="padding: 10px 2px;"
        >
          <b-form-select
              id="locale"
              v-model="locale"
              :options="localeList"
              @change="$v.locale.$model = $event"
              @update="setIsEditResource"
              :state="!$v.locale.$dirty ? null :(!$v.locale.$error)"
              :disabled="disableLocaleInput"
              value-field="name"
              text-field="name"
          ></b-form-select>
          <p v-if="$v.locale.$dirty && !$v.locale.required" class="text-danger">
            {{ $t('administrator.errors.required') }}
          </p>
        </b-form-group>

        <b-form-group class="mt-4">
          <b-form-checkbox
              v-model="showEditor"
          >
            {{ $t('cms.resource.show_editor') }}
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
            :label="$t('cms.resource.excerpt')"
            label-for="slug"
            class="mt-4"
        >
          <editor
              v-if="showEditor"
              :apiKey="editorApiKey"
              :init="{
          height: 200,
          plugins: editorPlugins,
          toolbar: editorToolbar
          }"
              v-model="excerpt"
              @input="setIsEditResource"
          />
          <b-form-textarea
              v-if="!showEditor"
              id="excerpt"
              v-model="excerpt"
              rows="4"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
            :label="$t('cms.resource.content')"
            label-for="slug"
            class="mt-4"
        >
          <editor
              v-if="showEditor"
              :apiKey="editorApiKey"
              :init="{
          height: 500,
          plugins: editorPlugins,
          toolbar: editorToolbar
          }"
              v-model="content"
              @input="setIsEditResource"
          />
          <b-form-textarea
              v-if="!showEditor"
              id="content"
              v-model="content"
              rows="10"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
            :label="$t('cms.resource.author')"
            label-for="author"
            label-cols-lg="3"
            class="bg-light"
            style="padding: 10px 2px;"
        >
          <b-form-input
              id="author"
              type="text"
              :value="author"
              @update="setIsEditResource"
              :disabled="resourceId ? true : false"
              trim></b-form-input>
        </b-form-group>

        <b-form-group
            :label="$t('cms.resource.file')"
            label-for="file"
            style="position: relative"
            label-cols-lg="3"
        >
          <div v-if="resourceFile">
            <a :href="resourceFile.url" target="_blank">{{ resourceFile.name }}</a>
            <b-button type="button" class="mr-3" @click="deleteFile" size="sm" variant="danger">{{ $t('cms.btn.delete_file') }}</b-button>
          </div>
          <b-form-file
              v-if="!resourceFile"
              v-model="resourceFile"
              :disabled="!resourceId"
              :placeholder="$t('import.file.choose_label')"
              :drop-placeholder="$t('import.file.drop_placeholder')"
              :browse-text="$t('import.file.browse_text')"
              @change="saveFile"
              id="file" ref="file">
          </b-form-file>
        </b-form-group>
      </div>
    </b-form>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {required} from 'vuelidate/lib/validators';
import alertify from "alertifyjs";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    'editor': Editor
  },
  props: {
    isCreateType: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      showEditor: false,
      perentIdRootId: 0,
      statuses: [
        {value: 'published', text: this.$t('option.published')},
        {value: 'draft', text: this.$t('option.draft')},
      ],
      editorApiKey: '2tx3tkl36siszy9gz7yk3mg7cmhc88dsg3n37pl1mjjodevx',
      editorPlugins: [
        'advlist autolink lists link image charmap',
        'searchreplace visualblocks code fullscreen',
        'print preview anchor insertdatetime media',
        'paste code help wordcount table', 'code'
      ],
      editorToolbar: 'undo redo | formatselect | bold italic | \
        alignleft aligncenter alignright | \
        code bullist numlist outdent indent | help'
    }
  },
  validations: {
    title: {
      required
    },
    locale: {
      required
    }
  },
  watch: {
    locale(newValue) {
      this.$store.dispatch('cms/getAvailableParents', newValue);
    },
    showValidationStatus() {
      this.showOrResetValidators(this.showValidationStatus);
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.cms.isLoading,
      localeList: (state) => state.cms.localeList,
      showValidationStatus: (state) => state.cms.showValidationStatus,
      availableParents: (state) => state.cms.availableParents,
      resourceId: (state) => state.cms.resourceDetails.id,
      resourceParentId: (state) => state.cms.resourceDetails.parentId,
      localeFromMainStore: (state) => state.cms.locale,
      userEmail: (state) => state.root.user.login,
      resourceStructure: (state) => state.cms.resourceStructure
    }),
    filteredAvailableParents() {
      const filteredParents = this.availableParents.filter(item => {
        return item.id !== this.resourceId;
      });

      filteredParents.unshift({
        id: null,
        title: "ROOT"
      });

      this.setParentIdAsRootElement();

      return filteredParents;
    },
    idFromUrl() {
      return this.$route.params.id;
    },
    localeFromUrl() {
      return this.$route.params.locale;
    },
    disableLocaleInput() {
      return this.resourceId !== null;
    },
    title: {
      get() {
        return this.$store.state.cms.resourceDetails.title;
      },
      set(value) {
        this.$store.commit('cms/SET_RESOURCE_FORM_TITLE', value)
      }
    },
    menuTitle: {
      get() {
        return this.$store.state.cms.resourceDetails.menuTitle;
      },
      set(value) {
        this.$store.commit('cms/SET_RESOURCE_FORM_MENU_TITLE', value)
      }
    },
    parentId: {
      get() {
        return this.$store.state.cms.resourceDetails.parentId;
      },
      set(value) {
        this.$store.commit('cms/SET_RESOURCE_FORM_PARENT_ID', value)
      }
    },
    status: {
      get() {
        return this.$store.state.cms.resourceDetails.status;
      },
      set(value) {
        this.$store.commit('cms/SET_RESOURCE_FORM_STATUS', value)
      }
    },
    locale: {
      get() {
        return this.$store.state.cms.resourceDetails.locale;
      },
      set(value) {
        this.$store.commit('cms/SET_RESOURCE_FORM_LOCALE', value)
      }
    },
    excerpt: {
      get() {
        return this.$store.state.cms.resourceDetails.excerpt;
      },
      set(value) {
        this.$store.commit('cms/SET_RESOURCE_FORM_EXCERPT', value)
      }
    },
    content: {
      get() {
        return this.$store.state.cms.resourceDetails.content;
      },
      set(value) {
        this.$store.commit('cms/SET_RESOURCE_FORM_CONTENT', value)
      }
    },
    author: {
      get() {
        return this.$store.state.cms.resourceDetails.author;
      },
      set(value) {
        this.$store.commit('cms/SET_RESOURCE_FORM_AUTHOR', value)
      }
    },
    resourceFile: {
      get() {
        return this.$store.state.cms.resourceFile;
      },
      set(value) {
        this.$store.commit('cms/SET_RESOURCE_FORM_FILE', value)
      }
    }
  },
  beforeMount() {
    this.$store.dispatch('cms/getAvailableParents', this.localeFromMainStore);
  },
  mounted() {
    this.showOrResetValidators(this.showValidationStatus);

    if(this.localeFromUrl) {
      this.$store.commit('cms/SET_LOCALE', this.localeFromUrl);
    }

    if(!this.resourceId) {
      this.$store.commit('cms/SET_RESOURCE_FORM_AUTHOR', this.userEmail);
    }
  },
  updated() {
    this.showOrResetValidators(this.showValidationStatus);
  },
  methods: {
    setParentIdAsRootElement() {
      if(this.resourceParentId === this.perentIdRootId) {
        this.$store.commit('cms/SET_RESOURCE_FORM_PARENT_ID', null);
      }
    },
    setIsEditResource() {
      this.$store.commit('cms/SET_IS_EDIT_RESOURCE', true);
    },
    showOrResetValidators(value) {
      if(value) {
        this.$v.$touch();
      } else {
        this.$v.$reset();
      }
    },
    saveFile() {
      setTimeout(() => {
        const formData = new FormData();

        formData.append('file', this.resourceFile, this.resourceFile.fileName);
        formData.append('id', this.idFromUrl);

        this.$store.dispatch('cms/uploadResourceFile', formData)
        .then(() => {
          alertify.success(this.$t('alert.success'));
        }).catch(() => {
          alertify.error(this.$t('alert.error'));
        });
      },1000);
    },
    deleteFile() {
        this.$store.dispatch('cms/deleteResourceFile', this.idFromUrl)
        .then(() => {
          this.$store.commit('cms/SET_RESOURCE_FORM_FILE', null);
          alertify.success(this.$t('alert.success'));
        }).catch(() => {
          alertify.error(this.$t('alert.error'));
        });
    },
  }
}
</script>
